// Initialize Firebase
var config = {
  apiKey: "AIzaSyCI6FUKjT9Ew46hPrvpLrnZt66cqWKhofI",
  authDomain: "bodegasa10000.firebaseapp.com",
  databaseURL: "https://bodegasa10000.firebaseio.com",
  projectId: "bodegasa10000",
  storageBucket: "bodegasa10000.appspot.com",
  messagingSenderId: "1094470415402"
};
  firebase.initializeApp(config);

// Reference messages collection
var messagesRef = firebase.database().ref('messages');

// Listen for form submit
document.getElementById('form').addEventListener('submit', submitForm);

// Submit form
function submitForm(e){
  e.preventDefault();

  // Get values
  var name = getInputVal('name');
  var email = getInputVal('email');
  var phone = getInputVal('phone');
  var message = getInputVal('message');

  // Save message
  saveMessage(name, email, phone, message);

  // Show alert
  document.querySelector('.alert').style.display = 'block';

  // Hide alert after 3 seconds
  setTimeout(function(){
    document.querySelector('.alert').style.display = 'none';
    window.location.href = "https://metroarea.com.co/parque/19";
  },500);

}

// Function to get get form values
function getInputVal(id){
  return document.getElementById(id).value;
}

function getCurrentDate() {
  var today = new Date();
  var s = today.getSeconds();
  var m = today.getMinutes();
  var h = today.getHours();
  var dd = today.getDate();
  var mm = today.getMonth(); //January is 0!
  var yyyy = today.getFullYear();

  var weekday = new Array("Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado");
  var month = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");

  var n = weekday[today.getDay()];
  var mm = month[today.getMonth()];

  s = formatTime(s);
  m = formatTime(m);
  h = formatTime(h);
  dd = formatTime(dd);

  today = n + ' ' + dd + ' de ' + mm + ' de ' + yyyy + ' - ' + h + ':' + m + ':' + s;
  return today;
}

function formatTime(t) {
  if(t<10) {
      t = '0'+t;
  }
  return t;
}

// Save message to firebase
function saveMessage(name, email, phone, message){
  var newMessageRef = messagesRef.push();
  var date = getCurrentDate();
  newMessageRef.set({
    name: name,
    email:email,
    phone:phone,
    message:message,
    date: date,
  });
}
